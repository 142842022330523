.root {

    font-size: 18px;
    cursor: pointer;
    background: white;
    color: black;
    border-radius: 5px;
    padding: 0.8rem 10px;
    font-weight: 700;
    flex-shrink: 0;

    :global(.fa-runnning) {
        //margin-left: 10px;
        margin-right: 0.5rem;
    }

    :global(.fa-spinner) {
        //margin-left: 10px;
        margin-right: 0.5rem;
    }

    :global(.fa-exclamation-triangle) {
        //margin-left: 10px;
        margin-right: 0.3rem;
    }
}

.error {
    font-size: 0.7em;
    color: #f44336;
}
@media (max-width:562px){
    .root{
        font-size: 15px;
    }
}

