@import '../global/global.scss';

.selectMenuItem {
    display: flex;
    align-items: center;

    .selectMenuItemText {
        margin-left: 1em;
    }
}

h2 {
    color: white;
    margin-bottom: 1px;
    margin-top: 1px;
}

.message {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
    color: $error-text-color;
    text-align: center;
}

.fieldsContainer {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;

    .form {
        width: 100%;

        .forgot {
            width: 100%;
            text-align: center;
            margin-bottom: 10px;

            span {
                color: $defaultColorText;
                cursor: pointer;
                text-decoration: underline;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        .field {
            width: 100%;
            margin: 20px 0;

            .localeContiner {
                display: flex;
                flex-direction: row;

                .select {
                    flex: 1;
                    margin-left: 1em;
                }
            }
        }

        .buttonCancel {
            display: flex;
            justify-content: center;
        }

        .textMessages {
            margin-bottom: 10px;
            font-size: 16px;
        }

        .fieldSignIn {
            width: 100%;
            text-align: center;
            margin: 15px 0;
            display: flex;
            flex-direction: column;
            color: $error-text-color;
            font-weight: bold;
            font-size: 14px;

            :global(.MuiButton-root) {
                margin-bottom: 1em;
            }

            .message {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }

        .forgotPasswordContainer {
            width: 100%;
            text-align: center;
            margin: 15px 0;
            display: flex;
            flex-direction: column;
            // color: $error-text-color;
            // font-weight: bold;
            //font-size: 14px;

            :global(.MuiButton-root) {
                margin-bottom: 1em;
            }

            .message {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
    }
}

.recoverSession {
    font-size: 24px;
}

.formContainer {
    // margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    // border-radius: 20px;
    //padding: 20px;
    //  width: fit-content;
    z-index: 1;
    align-items: center;
    flex: 2;
    justify-content: center;
    border-left-width: 13px;
    border-left-style: outset;
}