@import '../global/global.scss';

.root {
    background: $main-back-color;
    font-family: $main-font-family;
    color: $main-front-color;
    padding: 15px;
    padding-left: 1em;
    padding-top: 20px;

    .titleContainer {
        h3 {
            font-size: 2em;
            margin: 0px;
        }
    }

    .sliderContainer {
        .slider {
            padding: 1em;
            padding-top: 3em;
        }

        margin-left:10px;
    }

    .explain {
        margin: 1em;
        margin-top: 2em;
        margin-right: 4em;
        line-height: 1.4em;
    }

    .form {
        margin: 1em;
        margin-top: 2em;
        margin-right: 4em;

        .control {
            padding: 10px;
            padding-right: 0px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .birthdayContainer {
        margin-left: 8px;
    }

    .multiSelect {
        padding-left: 8px;
        margin-top: 2.5em;
        margin-bottom: 3.5em;
    }
}



.contents {
    margin: 10px;
    height: 100%;
}

.multiSelectLabel {
    position: absolute;
    top: -15px;
    z-index: 2;
    padding-right: 8px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.84);
    // background: #222222;
}

.multiSelectLabelError {
    color: #f44336;
}

.multiSelect {
    position: relative;
}

.multiSelectError {
    color: #f44336;
    font-size: 12px;
    margin-top: 0.4em;
}

.groupBoxRoot {
    border: 1px solid rgba(255, 255, 255, 0.27);
    border-radius: 4px;
    margin-bottom: 2em;
    padding: 1em;
    padding-top: 2em;
    padding-right: 2em;
    padding-bottom: 2em;
    position: relative;
    background: #222222;

    .controlInline {
        margin-bottom: 0.25em;
        padding: 0.5em;
        padding-top: 1em;
    }

    .control {
        background: #222222;
    }

    .error {
        color: #f44336;
        margin-left: 1em;
        font-size: 0.9rem;
    }

    .controlError {
        border: 1px solid #f44336;
    }

    .label {
        position: absolute;
        top: 0px;
        left: 0px;
        transform: translate(4px, -11px) scale(0.9);
        background: #222222;
        border-left: 5px solid #222222;
        border-right: 6px solid #222222;
    }

    .labelError {
        color: #f44336;
    }
}

.errorWarning {
    color: #f44336;
    font-weight: bold;
    text-align: right;
    margin-bottom: 1em;
}