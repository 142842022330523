.resultsContainer{

     //margin: 7px 10px 0px 10px;
     margin-bottom: 30px;
     


    .results {
        //flex-wrap: wrap;
        position: relative;
        //width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        padding: 0 4% 5px 4%;

        .hit {
            width: 16.6%;
            margin-right: 5px;

            :global(.rhf-swiper-slide) {
                width: 296px;
                height: 166px;
            }
        }
        
    }
    .results > :first-child{
        > div{
            transform-origin: left;
        }
    }
    .results > :last-child{
        margin-right: 0px;
        > div{
            transform-origin: right;
        }
    }
    .results:hover{
        z-index: 2;
    }
}

@media (max-width: 499px) {
    .resultsContainer .results .hit  {
        width:50%;
        }
}
  
@media (min-width: 500px) and (max-width: 799px){
    .resultsContainer .results .hit  {
      width:33.3%;
     }

}
  
@media (min-width: 800px) and (max-width: 1099px){
    .resultsContainer .results .hit  {
      width:25%;
     }
}
  
@media (min-width: 1100px) and (max-width: 1399px){
    .resultsContainer .results .hit  {
      width: 20%;
     }
}
  
@media (min-width: 1400px){
    .resultsContainer .results .hit  {
      width:16.6%;
     }
}