@import '../global/global.scss';

.loginContainer {
    //height: 100vh;
    position: relative;
    color: $defaultColorText;
    display: flex;
    flex-direction: row;

    .logo {
        max-width: 250px;
        align-self: flex-end;
        padding: 10px 20px 10px 0;
    }

    @media (pointer:coarse),
    (orientation:portrait) {
        .logo {
            align-self: center;
        }
    }

    h2 {
        color: white;
        margin-bottom: 1px;
        margin-top: 1px;
    }

    .message {
        font-size: 24px;
        margin-top: 10px;
        font-weight: bold;
        color: $error-text-color;
    }

    .fieldsContainer {
        // width: 310px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        .form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .field {
                width: 100%;
                // margin: 20px 0;
                font-size: 14px;
                text-align: center;

            }

            .recaptcha {

                margin: 20px 0;
                font-size: 14px;
                text-align: center;

            }

            .fieldSignIn {
                width: 100%;
                text-align: center;
                margin: 15px 0;

                .message {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }


        }
    }
}

.textCongrats {
    font-size: 20px;
    color: $defaultColorText;
    text-align: center;
    font-weight: bold;
}

.textInstructions {
    font-size: 16;
    color: $defaultColorText;
    text-align: center;

}

.loginImage {
    background-image: url(https://drrbcrbjxn8ae.cloudfront.net/web/assets/img/back_landing_20240220.jpg);
    flex: 3;
}

@media (pointer:coarse),
(orientation:portrait) {
    .loginImage {
        flex: 0;
    }

}

.recoverSession {
    font-size: 24px;
}

.formContainer {
    /*margin-top: 30px;
    display: flex;
    flex-direction: row;
    background: white;
    border-radius: 20px;
    padding: 20px;*/

    // margin-top: 10px;
    // margin-bottom: 10px;
    // display: flex;
    // flex-direction: column;
    // background: rgba(0, 0, 0, 0.8);
    // border-radius: 20px;
    // //padding: 20px;
    // width: 60%;
    // z-index: 1;
    // margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    // border-radius: 20px;
    //padding: 20px;
    //  width: fit-content;
    z-index: 1;
    align-items: center;
    flex: 2;
    justify-content: center;
    border-left-width: 13px;
    border-left-style: outset;
}


.formContainer * {
    z-index: 10;
}

.logoContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;
}

.logoImgSize {
    width: 70%;
    // border-radius: 20px;
    max-width: 350px;
}

.contentWrapper {
    margin-top: 10px;
    width: 340px;
}

.priceWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.plansWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 40%; */
    justify-content: space-evenly;
    margin-top: 40px;
    /* align-items: center; */
    /* align-content: center; */
    /* align-self: center; */
}

.errorMessage {
    color: $error-text-color;
    margin-bottom: 5px;
    font-size: 14px;
}

@media (max-width: 1024px) {
    .formContainer {
        width: fit-content;
    }

}

@media (max-width: 600px) {

    .contentWrapper {

        width: 100%;
    }

    .loginContainer {
        justify-content: flex-start;

        .logo {
            width: 70%;
        }
    }

    .formContainer {
        flex-direction: column;
        //padding: 20px 0 0 0;

    }

    .logoImgSize {
        // width: 60%;

    }

    .logoContainer {
        justify-content: center;
        padding: 20px 0 0 0;
    }
}