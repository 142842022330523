@import '../../global/global.scss';

.root {
  cursor: pointer;
  font-size: 36px;
  color: $main-front-color;
  margin: 25px;
}

.share {
  font-size: 22px;
  cursor: pointer;
}

.menu {
  background: #333333;
  border-radius: 5px;
  display: flex;
  margin-left: 3px;
  height: 34px;
  width: 110px;
  justify-content: space-evenly;
  align-items: center;
  

    .menuItem {
        //border-bottom: 1px solid #cccccc;
        //padding: 10px;
        cursor: pointer;
    }

    .menuItem:last-child {
        //border-bottom: 0px solid #cccccc;
    }

    .menuItem:hover {
        background: #666666;
    }
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
