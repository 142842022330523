@import '../global/global.scss';

.root {
    padding: 15px;

    .infoContainer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    @media (max-width: 499px) {
        .infoContainer {
            flex-direction: column;
        }
    }

    .itemContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }

    .itemTitle {
        font-size: 16px;
        color: #aaa;
        font-weight: bold;
        padding-bottom: 5px;
    }

    .itemValue {
        font-size: 16px;

    }

    .logo {
        width: 200px;

    }

    .titleContainer {
        h3 {
            font-size: 2em;
            margin: 0px;
        }
    }

    .sliderContainer {
        .slider {
            padding: 30px 0;

        }

        margin-left:10px;
    }

    .explain {
        //text-align: justify;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 22px;

    }

    .form {

        .formSectionTitle {
            font-size: 18px;
            font-weight: 700;
            padding: 10px;
        }

        .doubleItemProfile {
            display: flex;

            //align-items: center;
            .control {
                padding: 10px;
            }
        }

        .control {
            //padding: 10px;
            width: -webkit-fill-available;

            button {
                margin-bottom: 10px;
            }

        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
    }

    .birthdayContainer {
        /* margin-left: 8px; */
        padding: 10px;
        width: -webkit-fill-available;
    }

    .multiSelect {
        margin-top: 2.5em;
        margin-bottom: 3.5em;
    }
}



.contents {
    margin: 10px;
    height: 100%;
}

.multiSelectLabel {
    position: absolute;
    //top: -15px;
    z-index: 2;
    padding-right: 8px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.84);
    background: #222222;
}

.multiSelectLabelError {
    color: #f44336;
}

.multiSelect {
    position: relative;
}

.multiSelectError {
    color: #f44336;
    font-size: 12px;
    margin-top: 0.4em;
}

.groupBoxRoot {
    border: 1px solid rgba(255, 255, 255, 0.27);
    border-radius: 5px;
    margin-bottom: 2em;
    padding: 20px;
    position: relative;
    background: #222222;

    .controlInline {
        margin-bottom: 0.25em;
        padding: 0.5em;
        padding-top: 1em;
    }

    .control {
        background: #222222;
    }

    .error {
        color: #f44336;
        margin-left: 1em;
        font-size: 0.9rem;
    }

    .controlError {
        border: 1px solid #f44336;
    }

    .label {
        position: absolute;
        top: 0px;
        left: 0px;
        transform: translate(4px, -11px) scale(0.9);
        background: #222222;
        border-left: 5px solid #222222;
        border-right: 6px solid #222222;
    }

    .labelError {
        color: #f44336;
    }
}

.errorWarning {
    color: #f44336;
    font-weight: bold;
    text-align: right;
    margin-bottom: 15px;
    text-align: center;
    font-size: 14px;
}