@import '../global/global.scss';

.metadata {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 170px;

    .metadataIcon{
        font-size: 24px;
        color:#555555;
    }
    .metadataTitle {
        font-weight: bold;
        padding: 0.5em;
        display: inline-block;
        text-transform: uppercase;
        font-size: 14px;
    }

    .metadataValues {
        display: inline-block;
        font-size: 14px;
        color:#d2d2d2;
    }
}

.metadataContainer {      
    background-color: #222;
    padding: 15px;

    :global(.swiper-container) {
        overflow: hidden !important;
        padding-left: 30px;
        padding-right: 30px;
    }

    :global(.swiper-button-next) {
        color:$defaultColorText;
        font-weight: 700;
    }

    :global(.swiper-button-prev) {
        color:$defaultColorText;
        font-weight: 700;
    }
}
