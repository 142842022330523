@import '../global/global.scss';

.loginContainer {
    height: 100vh;
    position: relative;
    color: $defaultColorText;
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;

    .logo {
        max-width: 250px;
        align-self: center;
        padding: 10px 20px 10px 0;
    }

    @media (pointer:coarse),
    (orientation:portrait) {
        .logo {
            align-self: center;
        }
    }
}

.textMessage {
    font-size: 16px;
    margin-bottom: 10px;
    width: 70%;
    text-align: center;


}

.formContainer {
    // margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    // border-radius: 20px;
    //padding: 20px;
    //  width: fit-content;
    z-index: 1;
    align-items: center;
    flex: 2;
    justify-content: center;
    border-left-width: 13px;
    border-left-style: outset;
    overflow-y: scroll;
}

.formContainerCheckout {
    // margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    // border-radius: 20px;
    //padding: 20px;
    //  width: fit-content;
    z-index: 1;
    align-items: center;
    flex: 2;
    justify-content: flex-start;
    border-left-width: 13px;
    border-left-style: outset;
    overflow-y: scroll;
}

.loginImage {
    background: url(https://drrbcrbjxn8ae.cloudfront.net/web/assets/img/back_landing_20240220.jpg) no-repeat center center/cover;
    flex: 3;
}

.cookiesWrapper {
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: flex;
}

.cookiesContainer {
    width: 100vw;
    background-color: rgba(20, 20, 20, 0.9);
    padding: 15px 30px;
    z-index: 10;
}

.cookiesAligment {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.cookiesTitle {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 20px;
    color: #fff;
    display: flex;
    justify-content: flex-start
}

.cookiesMessage {
    font-size: 14px;
    color: #e5e5e5;
    flex: 2;
}

.cookiesButtonsContainer {
    flex-direction: column;
    display: flex;
    align-items: center;
    flex: 1
}

.cookiesButton {
    margin-bottom: 20px !important;
    width: 45%;
}

@media (max-width:639px) and (orientation:portrait) {
    .loginImage {
        background: url(https://drrbcrbjxn8ae.cloudfront.net/web/assets/img/back_landing_20240220.jpg) no-repeat center center/cover;
        height: 250px;
    }

    .cookiesContainer {
        width: 100%;
        background-color: rgba(20, 20, 20, 0.9);
        //border-radius: 10px;
        padding: 10px;
    }

    .cookiesTitle {
        font-size: 22px;
        font-weight: bold;
        padding: 20px;
        color: #fff;
        display: flex;
        justify-content: center
    }

    .cookiesButton {
        margin-bottom: 20;
        width: 100%;
    }

    .cookiesAligment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cookiesMessage {

        justify-content: center;
        font-size: 14px;
        padding-bottom: 20px;
        color: #e5e5e5;
        text-align: center;

    }

    .cookiesButtonsContainer {
        flex-direction: column;
        align-items: center;

    }

}

@media (min-width:640px) and (max-width:820px) and (orientation:portrait) {
    .loginImage {
        background: url(https://drrbcrbjxn8ae.cloudfront.net/web/assets/img/back_landing_20240220.jpg) no-repeat center center/cover;
        height: 400px;
    }

}


.formContainer * {
    z-index: 10;
}

.formContainerCheckout * {
    z-index: 10;
}

.logoContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;
}

.logoImgSize {
    width: 70%;
    border-radius: 20px;
    max-width: 350px;
}

.logoImgSizeRf {
    width: 70%;
    //border-radius: 20px;
    max-width: 350px;
}

.sectionTitle {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;

}

@media (max-width: 821px) and (orientation:portrait) {
    .loginContainer {
        //justify-content: flex-start;
        //flex-direction: column;
        display: block;

        .logo {
            width: 50%;
        }
    }

    .formContainer {
        flex-direction: column;
        border-width: 0px;
        justify-content: flex-start;
        margin-top: 15px;
        //padding: 20px 0 0 0;
        // overflow-y: inherit;
    }

    .formContainerCheckout {
        flex-direction: column;
        border-width: 0px;
        justify-content: flex-start;
        margin-top: 15px;
        //padding: 20px 0 0 0;
        // overflow-y: inherit;
    }

    .logoImgSize {
        width: 60%;

    }

    .logoContainer {
        justify-content: center;
        padding: 20px 0 0 0;
    }
}