@import '../../global/global.scss';


h1 {
    font-size: 3rem;
    text-transform: uppercase;
}



.mainContainer {
    /* display: flex; */
    /* flex-direction: row; */
    max-width: 100%;
    color: #e5e5e5;
    min-height: 550px;
    z-index: 0;
    position: relative;
    width: 100%;
}

.textContainer {


    min-height: 100vh;
    width: 43.75%;
    float: left;
    padding-left: 0.46875rem rem;
    padding-right: 0.46875rem;
    position: relative;
}

.imageContainer {

    background-image: url(https://drrbcrbjxn8ae.cloudfront.net/web/assets/img/back_landing_20240220.jpg);

    left: auto;
    right: 0;
    width: 66.25%;
    z-index: -2;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    top: 0;
}

.gradintText {
    background: linear-gradient(to right, rgb(15, 15, 14) 77%, rgba(15, 15, 14, 0) 100%) 0% 0% / 100% 100%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.text {
    z-index: 900;
    position: relative;
    padding: 4.0625rem !important;
    font-size: 18px;
}

title {
    color: #fff;
    font-size: 20rem !important;
    font-weight: 900;
}

.logo {
    max-width: 200;
    text-align: center;
    width: -webkit-fill-available;
}

.devicesContainer {
    display: flex;
    flex-direction: row;
}

.devicesText {
    color: #e5e5e5;
    padding: 2em;
    flex: 1;
}

.devicesImg {
    width: "100%";
}

.row:after {
    clear: both;
}

.row:after,
.row:before {
    content: " ";
    display: table;
}

.signUpContainer {
    text-align: left !important;
    padding-bottom: 1rem;
    margin-bottom: 10px;
}

.logInText {
    text-align: left !important;
    padding-bottom: 1rem;
    font-weight: bold;
    font-size: 16px;
    padding-right: 0.7rem;
}

.logInContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.button {
    color: #fff;
    border-color: $ColorRF;
    background-color: $ColorRF;
    border: 1px solid #0006;
    border-radius: 3px;
    font-family: BrandonMedium, sans-serif;
    font-size: 18px;
    padding: 0.5em 30px;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 700;
}

.buttonKeep {
    color: #111;
    border-color: #ebeaea;
    background-color: #e5e5e5;
    border: 1px solid #0006;
    border-radius: 3px;
    font-family: BrandonMedium, sans-serif;
    font-size: 18px;
    padding: 0.5em 30px;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 700;
}

.button2 {
    color: $ColorRF;
    //  border-color: #f6f6f6;
    // background-color: #e5e5e5;
    // border: 1px solid #0006;
    // border-radius: 3px;
    font-family: BrandonMedium, sans-serif;
    font-size: 18px;
    // padding: 0.5em 30px;
    //text-decoration: none;
    white-space: nowrap;
    text-transform: uppercase;
}




.textTotal {
    font-size: 16;
    font-weight: bold;
    color: #e5e5e5;
}

.pIntro {
    font-size: 18px;
}

@media (max-width: 1024px) {

    .logo {
        max-width: 200px;
        text-align: center;
        // width: -webkit-fill-available;
    }

    h1 {
        //font-size: 1rem;
        text-align: center;
    }

    .gradintText {
        background: linear-gradient(to top, black 50%, #161616 100%) 0% 0%/100% 100%
    }

    .imageContainer {

        height: 0;
        left: 0;
        padding-top: 56.25%;
        right: auto;
        width: 100%;
    }

    .textContainer {
        margin-top: 56.25%;
        //min-height: 0;
        width: 100%;
    }

    .devicesContainer {
        display: flex;
        flex-direction: column;
    }

    .signUpContainer {
        text-align: center !important;
        padding-bottom: 1rem;
        padding-top: 1rem;

    }

    .logInContainer {
        //     flex-direction: column;
        //     align-items: flex-start;
        flex-direction: column;
        align-items: center;
    }

    .text {
        padding: 1rem !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .textContainer {
        margin-top: 35%;
    }

    .logInText {
        padding: 0;
    }

    .button {
        font-size: 14px;
    }




}