@import '../global/global.scss';

.selectMenuItem {
    display: flex;
    align-items: center;

    .selectMenuItemText {
        margin-left: 1em;
    }
}

.loginContainer {
    height: 100vh;
    position: relative;
    color: $defaultColorText;
    display: flex;
    flex-direction: row;

    .logo {
        max-width: 250px;
        align-self: center;
        padding: 10px 20px 10px 0;
    }

    @media (pointer:coarse),
    (orientation:portrait) {
        .logo {
            align-self: center;
        }
    }


    h2 {
        color: white;
        margin-bottom: 1px;
        margin-top: 1px;
    }

    .message {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
        color: $error-text-color;
        text-align: center;
    }

    .fieldsContainer {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        .form {
            width: 100%;

            .forgot {
                width: 100%;
                text-align: center;

                span {
                    color: $defaultColorText;
                    cursor: pointer;
                    //  text-decoration: underline;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }

            .field {
                width: 100%;
                margin: 20px 0;

                .localeContiner {
                    display: flex;
                    flex-direction: row;

                    .select {
                        flex: 1;
                        margin-left: 1em;
                    }
                }
            }

            .fieldSignIn {
                width: 100%;
                text-align: center;
                margin: 15px 0;
                display: flex;
                flex-direction: column;

                :global(.MuiButton-root) {
                    margin-bottom: 1em;
                }

                .message {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}


.recoverSession {
    font-size: 24px;
}

.formContainer {
    // margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    // border-radius: 20px;
    //padding: 20px;
    //  width: fit-content;
    z-index: 1;
    align-items: center;
    flex: 2;
    justify-content: center;
    border-left-width: 13px;
    border-left-style: outset;
}

.loginImage {
    background-image: url(https://drrbcrbjxn8ae.cloudfront.net/web/assets/img/back_landing_20240220.jpg);
    flex: 3;
}



.formContainer * {
    z-index: 10;
}

.logoContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;
}

.logoImgSize {
    width: 60%;
    border-radius: 20px;
    max-width: 350px;
}

@media (max-width: 500px) {
    .loginContainer {
        justify-content: flex-start;
        height: auto;

        .logo {
            width: 50%;
        }
    }

    .formContainer {
        flex-direction: column;
        //padding: 20px 0 0 0;

    }

    .logoImgSize {
        width: 60%;

    }

    .logoContainer {
        justify-content: center;
        padding: 20px 0 0 0;
    }
}