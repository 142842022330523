.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: none;
  z-index: unset;
}

.app {
  overflow-anchor: none;
}

@media (min-width: 1921px) {
  html {
    font-size: 16px;
  }

}

@media (min-width: 1281px) and (max-width: 1920px) {
  html {
    font-size: 0.85vw;
  }

}

@media (min-width: 841px) and (max-width: 1280px) {
  html {
    font-size: 1vw;
  }

}

@media (min-width: 481px) and (max-width: 840px) {
  html {
    font-size: 1.5vw;
  }
}

@media (min-width: 0) and (max-width: 480px) {
  html {
    font-size: 1.5vw;
  }
}



.library-swipers {
  margin-top: 2em;
}

.rhf-swiper-slide {
  position: absolute;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background: #666666;
}

.rhf-swiper-slide-large {
  background: #666666;
}

.MuiPopover-root {
  z-index: 50000000 !important;
}


.rhf-swiper-slide:hover .rhf-swiper-slide-caption {
  opacity: 1;
  transition: 0.3s;
  transition-delay: 0.2s;
}

.rhf-swiper-slide-caption {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  opacity: 0;
  display: flex;
  flex-direction: row;
}

.play-container {
  padding: 10px;
  align-self: center;
  font-size: 24px;
  cursor: pointer;
}

.caption-container {
  padding: 5px;
  flex-grow: 1;
}

.rhf-swiper-slide-caption p {
  margin: 1px;
}

.library-swipers .swiper-slide {
  width: 20%;

}

@media (max-width: 499px) {

  .library-swipers .swiper-slide,
  .resultsContainer .results .hit {
    width: 50%;
  }

  .library-swipers .swiper-slide-large {
    width: 50%;
  }
}

@media (min-width: 500px) and (max-width: 799px) {

  .library-swipers .swiper-slide,
  .resultsContainer .results .hit {
    width: 33.3%;
  }

  .library-swipers .swiper-slide-large {
    width: 33.3%;
  }
}

@media (min-width: 800px) and (max-width: 1099px) {

  .library-swipers .swiper-slide,
  .resultsContainer .results .hit {
    width: 25%;
  }

  .library-swipers .swiper-slide-large {
    width: 25%;
  }
}

@media (min-width: 1100px) and (max-width: 1399px) {

  .library-swipers .swiper-slide,
  .resultsContainer .results .hit {
    width: 20%;
  }

  .library-swipers .swiper-slide-large {
    width: 20%;
  }
}

@media (min-width: 1400px) {

  .library-swipers .swiper-slide,
  .resultsContainer .results .hit {
    width: 16.6%;
  }

  .library-swipers .swiper-slide-large {
    width: 16.6%;
  }
}

.lazy-load-image-background {
  width: 100%;
}

.lazy-load-image-background img {
  width: 100%;
  border-radius: 4px;
}

.lazy-load-image-background img:hover {

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.swiper-container {
  overflow: visible !important;
  z-index: unset;
  padding: 0px 4%;

}

/*
@media (max-width: 1024px){*/
@media (pointer:coarse) {
  .swiper-container {
    overflow: hidden !important;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    display: none;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    display: none;
  }
}

.rhf-swiper-container {
  margin-bottom: 30px;

}

.rhf-swiper-container .swiper-container .swiper-button-prev {
  background-color: rgba(11, 11, 11, 0.5);
  height: 100%;
  width: 3.8%;
  left: 0;
  top: 22px;
  color: #e5e5e5;
  font-weight: bold;
}

.swiper-button-prev:after {

  font-size: 22px;

}

.swiper-button-next:after {

  font-size: 22px;

}


.rhf-swiper-container .swiper-container .swiper-button-next {
  background-color: rgba(11, 11, 11, 0.5);
  height: 100%;
  width: 3.8%;
  right: 0;
  top: 22px;
  color: #e5e5e5;
  font-weight: bold;
}

.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-next.swiper-button-disabled {
  opacity: 0;
}


.rhf-swiper-slide img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.rhf-swiper-slide-large img {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hide {
  position: fixed;
  opacity: 0.2;
  transition: 0.2s ease-in-out;
  left: 0;
  right: 0;
  pointer-events: none;
}

.unhide {
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.popup-fade-appear {
  opacity: 0;
  transform: scaleY(0.8) scaleX(0.8);
}

.popup-fade-appear-done {
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.popup-profile-fade-appear {
  opacity: 0;
}

.popup-profile-fade-appear-done {
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.scroll-loading {
  height: 5px;
  background: #222222;
  width: 100%;
}