@import '../../global/global.scss';

.root {

    font-size: 18px;
    cursor: pointer;
    background-color: $ColorRH;
    color: black;
    border-radius: 5px;
    padding: 0.8rem 10px;
    flex-shrink: 0;
    margin-right: 32px;

    :global(.fa-play) {
        //margin-left: 10px;
        margin-right: 0.5rem;
    }
}

.rootRftv {

    font-size: 18px;
    cursor: pointer;
    background-color: $ColorRF;
    color: $rftvTextColor;
    border-radius: 5px;
    padding: 0.8rem 10px;
    flex-shrink: 0;
    margin-right: 32px;

    :global(.fa-play) {
        //margin-left: 10px;
        margin-right: 0.5rem;
    }
}


@media (max-width:562px) {
    .root {
        font-size: 14px;
    }
}