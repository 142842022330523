@import '../global/global.scss';

@keyframes Pulsate {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.round {
    background: $ColorRH;
    border-radius: 50%;
    line-height: 18px;
    font-size: 14px;
    width: 18px;
    text-align: center;
    display: inline-block;
    margin-right: 6px;
}

.roundRf {
    background: $ColorRF;
    border-radius: 50%;
    line-height: 18px;
    font-size: 14px;
    width: 18px;
    text-align: center;
    display: inline-block;
    margin-right: 6px;
}

:global(.side-menu-fade-appear) {
    opacity: 0;

    .selectables {
        flex-grow: 0.00001;
    }
}

:global(.side-menu-fade-appear-done) {
    opacity: 1;
    transition: 0.2s ease-in-out;

    .selectables {
        transition: flex-grow 0.2s ease-in-out;
        flex-grow: 0.75;
    }
}

.sideMenu {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    left: 0px;
    display: flex;
    flex-direction: row;
    font-size: 26px;
    z-index: 10;

    .margin {
        flex: 0.1;
    }

    .selectables {

        background: black;
        flex: 0.9;

        .close {
            text-align: right;
            margin-top: 0.4em;
            margin-right: 0.2em;
        }

        .menuItem {
            color: #e5e5e5;
            padding: 10px 20px;
            font-size: 18px;
            font-weight: 700;
            justify-content: flex-end;
            align-items: center;

            .menuItemText {
                padding-right: 20px;
            }

            svg {
                font-size: 18px;
                color: #e5e5e5;
                color: white;
            }
        }

        .menuItem:last-child {
            //border-bottom: 0px solid #cccccc;
        }

        .menuItem:hover {

            color: white;
            background: #111;
        }
    }
}


.menu {
    background: black;
    padding: 5px 0;
    min-width: 160px;
    z-index: 30000000;
    margin-top: 3px;
    border: solid 1px rgba(255, 255, 255, .35);
    opacity: 0.8;

    .menuItem {
        color: #888;
        border-bottom: solid 1px rgba(255, 255, 255, .35);
        padding: 10px;
        font-size: 14px;
    }

    .menuItem:last-child {
        border-bottom: 0px solid #cccccc;
    }

    .menuItem:hover {

        color: white;
        background: #111;
    }
}

.notificationBadge {
    bottom: 20px;
    left: 55px;
    position: absolute;

    svg {
        font-size: 10px;
    }

    span {
        width: 14px;
        line-height: 14px;
        font-size: 10px;
        font-weight: 700;
    }
}

.flag {
    padding-right: 10px;
    display: inherit;

    >div {
        display: inherit;
    }
}

.hamburgerMenuContainer {
    display: none;

    >span>svg {
        font-size: 28px;
    }
}

@media (pointer:coarse) {
    .menu {
        max-height: 100vh;
        overflow-y: hidden;
        min-width: 93vw;
        opacity: 0.9;
    }

    .hamburgerMenuContainer {
        display: flex;
        // margin-left: 25px;
        position: relative;
        align-items: center;
    }

    .toolbar {
        display: none !important;
    }
}


@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

:global(.popup-content) {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    z-index: 30000000 !important;
}


.menuItem {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .text {
        flex: 1;
        margin-right: 1em;

        .title {
            font-size: 14px;
            margin-bottom: 0.5em;
        }

        .titleLink {
            cursor: pointer;
        }

        .date {
            font-size: 12px;
            color: #e5e5e5;
            text-align: end;
        }
    }

    .actions {
        font-size: 14px;
        cursor: pointer;
        text-align: center;
    }
}

.root {
    top: 0;
    height: 68px;
    left: 0;
    right: 0;
    position: fixed;
    background: transparent;
    display: flex;
    align-items: center;
    padding: 0px 3%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0));
    transition: background-color 0.4s;
    z-index: 30;

    .left {
        flex: 1;

        .logo {
            font-weight: bold;
            font-size: 20px;
            display: flex;
            align-items: center;

            // width: 100px;
            .logoGymImg {
                width: 115px;
            }

            .logoSellerImg {
                width: 250px;
            }

            @media(pointer:coarse) {
                .logoGymImg {
                    width: 90px;
                }

                .logoSellerImg {
                    width: 150px;
                }
            }

            .by {
                font-size: 14px;
            }
        }
    }

    .right {
        font-size: 21px;
        display: flex;
        align-items: center;

        .toolbar {
            display: flex;
            width: 180px;
            justify-content: space-between;
            align-items: center;
        }

        .logoRadicalImg {
            width: 150px;
            margin-right: 15px;
        }

        @media (pointer:coarse) {
            .logoRadicalImg {
                width: 100px;
            }
        }
    }

    :global(.fa-user) {
        cursor: pointer;
    }

    :global(.fa-search) {
        cursor: pointer;
    }

    :global(.fa-calendar) {
        cursor: pointer;
    }

    :global(.fa-bell) {
        cursor: pointer;
    }
}

@media (pointer:coarse) {
    .root {
        height: 50px;

        .left {
            .logo {
                img {
                    width: 6.5em;
                }

                .gymlogo {
                    width: 3.7em;
                    margin-left: 2em;
                }
            }
        }
    }


}