@import '../global/global.scss';


.textShadow {
    background: rgba(34, 34, 34, 0.85);
    // border-radius: 5px;
    //padding: 0.4em;
    width: 100%;
}

.root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: $main-back-color;
    font-family: $main-font-family;
    color: $main-front-color;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;

    :global(.swiper-wrapper) {
        width: 100%;
        // height: 100vh;
    }

    :global(.swiper-slide) {
        width: 100vw;
        height: 100vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    :global(.swiper-container) {
        padding: 0px;
    }

    :global(.swiper-button-next::after) {
        font-size: 30px;
        color: $ColorRH;
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .6));
        font-weight: bold;
    }

    :global(.swiper-button-prev::after) {
        font-size: 30px;
        color: $ColorRH;
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .6));
        font-weight: bold;
    }

}

.slide1 {
    display: flex;
    flex-direction: column;
    // margin: 10em;
    height: 100%;
    width: 100%;
    justify-content: center;
    padding-right: 3em;
    padding-left: 3em;

    .slide1TitleContainer {
        .slide1Title {
            font-size: 2em;
        }

        margin-bottom: 3em;
    }
}


.profileButton {
    margin: 1em;
}

.accepted {
    margin-bottom: 1em;
}

.profile {
    display: flex;
    flex-direction: column;
    //margin: 10em;
    height: 100%;

    justify-content: center;
    align-items: center;

    .profileButtons {
        display: flex;
        flex-direction: row;
    }

    .profileTitle {
        font-size: 30px;
        margin-bottom: 15px;
        color: #3fb3e4;
        font-weight: 700;
    }

    .profileTitleRfTv {
        font-size: 30px;
        margin-bottom: 15px;
        color: $ColorRF;
        font-weight: 700;
    }

    .profileExplain {
        line-height: 24px;
        margin-bottom: 25px;
        width: 50%;
        font-size: 18px;
    }

    @media (pointer:coarse) {
        .profileExplain {
            width: 90%;
        }

        .profileTitle,
        .profileTitleRfTv {
            padding: 0 10px;
        }
    }
}

.noTyc {
    font-size: 3em;
    //margin: 10em;
    height: 100%;
}

.tyc {
    display: flex;
    flex-direction: column;
    height: 100%;
    //justify-content: center;
    align-items: center;
    width: 100%;

    .accept {
        max-width: "150px";

        >div>label>span>span>svg {
            font-size: 20px !important;
            color: $defaultColorText;
        }

        .checkboxLabel {
            margin-bottom: 1em;
            padding-right: 1em;
        }

        .tycAccepted {
            font-size: 16px;
            margin-bottom: 15px;

        }
    }

    .tycTitle {
        font-size: 30px;
        margin-bottom: 15px;
        color: #3fb3e4;
        font-weight: 700;
        padding-top: 20px;
    }

    .tycTitleRfTv {
        font-size: 30px;
        margin-bottom: 15px;
        color: $ColorRF;
        font-weight: 700;
        padding-top: 20px;
    }

    .tycText {
        border: 1px solid #cccccc;
        background: rgba(34, 34, 34, 0.85);
        border-radius: 5px;
        padding: 1em;
        overflow-y: scroll;
        flex: 0.7;
        max-height: 40vh;
        margin: 5px 5em;
        text-align: justify;
        max-width: 800px;
        width: 85%;
        font-size: 14px;
    }

}