@import '../global/global.scss';

.loginContainer {
    height: 100vh;
    position: relative;
    color: $defaultColorText;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;

    .logo {
        max-width: 250px;
        align-self: flex-end;
        padding: 10px 20px 10px 0;
    }

    @media (pointer:coarse),
    (orientation:portrait) {
        .logo {
            align-self: center;
        }
    }

    h2 {
        color: white;
        margin-bottom: 1px;
        margin-top: 1px;
    }

    .message {
        font-size: 24px;
        margin-top: 10px;
        font-weight: bold;
        color: $error-text-color;
    }

    .fieldsContainer {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        .form {
            width: 100%;

            .field {
                width: 100%;
                margin: 20px 0;
                font-size: 14px;
                text-align: center;

            }

            .fieldSignIn {
                width: 100%;
                text-align: center;
                margin: 15px 0;

                .message {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }

            .textInstructions {
                font-size: 16px;
                color: $defaultColorText;
                text-align: center;
            }
        }
    }
}

.recoverSession {
    font-size: 24px;
}

.formContainer {
    /*margin-top: 30px;
    display: flex;
    flex-direction: row;
    background: white;
    border-radius: 20px;
    padding: 20px;*/

    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    //padding: 20px;
    width: fit-content;
    z-index: 1;
}


.formContainer * {
    z-index: 10;
}

.logoContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;
}

.logoImgSize {
    width: 60%;
    border-radius: 20px;
    max-width: 350px;
}

@media (max-width: 600px) {
    .loginContainer {
        justify-content: flex-start;
        height: auto;

        .logo {
            width: 70%;
        }
    }

    .formContainer {
        flex-direction: column;
        //padding: 20px 0 0 0;

    }

    .logoImgSize {
        // width: 60%;

    }

    .logoContainer {
        justify-content: center;
        padding: 20px 0 0 0;
    }
}