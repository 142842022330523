@import '../global/global.scss';

.clickable {
    cursor: pointer;
}

.modalRoot {
    padding: 1em;
    padding-bottom: 2em;
}

.logoRadicalContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .logoRadical {
        width: 250px;
        padding-bottom: 10px;
    }

    @media (max-width:640px) {
        .logoRadical {
            width: 180px;
        }
    }
}

.title {
    font-size: 2em;
    text-align: center;
    text-transform: uppercase;
    color: #efefef;
}

.dataContainer {
    margin-top: 4em;
    background-color: #222;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.daySelectedTitle {
    text-transform: uppercase;
    font-size: 24px;
    color: #efefef;
    margin: 0px;
    text-align: center;

}

.summaryContainer {
    margin-top: 1em;
    margin-bottom: 2em;
    display: flex;
    text-align: center;
    height: 100%;
    justify-content: center;
}

.summaryContainerRfTv {
    margin-top: 1em;
    margin-bottom: 2em;
    display: flex;
    text-align: center;
    height: 100%;
    justify-content: center;
}

.summaryContainer>div {
    flex: 1;
}

.summaryContainerRfTv>div {
    flex: 1;
}

.summaryContainer>div>h3 {
    font-size: 16px;
    color: #afafaf;
    text-transform: uppercase;
    margin-block-end: 0px;

}

.summaryContainerRfTv>div>h3 {
    font-size: 16px;
    color: #afafaf;
    text-transform: uppercase;
    margin-block-end: 0px;

}

.summaryContainer>h4 {
    font-size: 16px;
    color: $ColorRH;
    text-transform: uppercase;
}

.summaryContainerRfTv>h4 {
    font-size: 16px;
    color: $ColorRF;
    text-transform: uppercase;
}

.summaryContainer>div>p {
    font-weight: bold;
    color: $ColorRH;
    font-size: 20px;
    margin: 3px 0px;
}



.summaryContainerRfTv>div>p {
    font-weight: bold;
    color: $ColorRF;
    font-size: 20px;
    margin: 3px 0px;
}

@media (max-width:640px) {
    .daySelectedTitle {
        font-size: 18px;
    }

    .summaryContainer>div>h3,
    .summaryContainerRfTv>div>h3 {
        font-size: 14px;
    }

    .summaryContainer>div>p,
    .summaryContainerRfTv>div>p {
        font-size: 18px;
    }

    .dayInfoContainer {
        //flex-direction: column;
    }

    .dayInfoContainer>div {
        //flex-direction: column;
        margin: 8px 0px;
        flex-direction: column;
    }

    .dayInfoContainer>div>p {
        margin: 0;
    }
}

.trainingSessionContainer {
    border-radius: 10px;
    background-color: #222;
    margin: 10px 0;
    text-transform: uppercase;
}

.trainingSessionContainer>h3 {
    font-size: 14px;
    color: #e5e5e5;
    border-bottom: 1px solid #bbb;
    padding: 10px 0px;
    margin: 0px 10px;
}

.dayInfoContainer {
    display: flex;
    color: #afafaf;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}

.dayInfoContainer>div {
    display: flex;
    flex: 1;
    justify-content: center;
}

.dataHighlight {
    padding-left: 4px;
    color: #efefef;
}

.root {
    margin-top: 0;
}

.separator {
    border-left: 3px solid #444;
    border-right: 3px solid #444;
}

.calendarRoot {
    color: $main-front-color;
    margin-top: 0;
    margin-bottom: 2em;

    .monthRootLoading {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 4em;
    }

    .monthRoot {
        display: flex;
        flex-direction: column;
        align-items: center;

        .monthWeek {
            display: flex;
            flex-direction: row;
            text-align: center;

            .monthWeekHeader {
                padding: 7px 1px;
                width: 75px;
                font-size: 14px;
            }

            @media (max-width:640px) {
                .monthWeekHeader {
                    font-size: 0px;
                }

                .monthWeekHeader::first-letter {
                    font-size: 12px;
                }

            }
        }

        .monthDay {
            cursor: pointer;
            border: 1px solid #666666;
            background: #444444;
            width: 75px;
            height: 50px;
            //padding: 4px;

            position: relative;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .monthDayNumber {
                //position: absolute;
                //left: 8px;
                //top: 8px;
                //text-align: left;
                background-color: #333;
                width: 100%;
                font-size: 16px;
                color: #888;
            }

            .monthDayCross {
                //margin-left: 8px;
                font-size: 24px;
                font-weight: bold;
                color: #555;
            }

            .monthDayCheck {
                //margin-left: 8px;
                font-size: 24px;
                font-weight: bold;
                color: $ColorRH;
            }

            .monthDayCheckRfTv {
                //margin-left: 8px;
                font-size: 24px;
                font-weight: bold;
                color: $ColorRF;
            }

            @media (max-width:640px) {
                .monthDayNumber {
                    font-size: 12px;
                }

            }
        }

        .monthDaySelected {
            background: #cccccc;

            .monthDayNumber {
                color: #fff;
                font-weight: bold;
            }

            .monthDayCheck,
            .monthDayCheckRfTv {
                //margin-left: 8px;
                font-size: 24px;
                font-weight: bold;
                color: #333;
            }
        }

        .monthDayFuture {
            cursor: initial;
            opacity: 0.3;

        }

        .monthDayOutside {
            cursor: initial;
            opacity: 0.2;
        }

        .weekDay {
            border: 1px solid #666666;
            background: #444444;
            cursor: pointer;
            margin-top: 5px;
            padding: 10px;
        }

        .weekhDayWithData {
            background: #888888;
        }
    }

    @media (max-width:640px) {
        .monthRoot {
            align-items: normal;
        }
    }
}