@import '../global/global.scss';


.root {
    background: $homeBackgroud;
    font-family: $main-font-family;
    color: $main-front-color;
    padding: 15px;
    //padding-left: 1em;
    //padding-top: 20px;
    height: 100vh;
    overflow-anchor: none;

    .titleSearch {
        font-size: 18px;
        font-weight: 700;
    }

    .explain {
        text-align: justify;
        font-size: 16px;
        padding: 15px 0;
        line-height: 22px;
    }

    .showMoreOptions {
        font-size: 18px;
        margin-top: 1em;
        cursor: pointer;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            color: $ColorRH;
        }
    }

    .showMoreOptionsRfTv {
        font-size: 18px;
        margin-top: 1em;
        cursor: pointer;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            color: $ColorRF;
        }
    }

    @media (pointer:coarse) {
        .showMoreOptions {
            font-size: 14px;
        }

    }

    .advancedOption {
        margin-top: 10px;
        width: -webkit-fill-available;
        padding: 10px;
    }

    .advancedOptionFirst {
        margin-top: 10px;
        width: -webkit-fill-available;
        padding: 10px;
    }

    .moreOptionsContiner {
        margin-top: 2em;

        .fieldGroupOfThree {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        @media (max-width:640px) {
            .fieldGroupOfThree {
                flex-direction: column;
            }
        }
    }

    .searchControls {
        margin-top: 1em;

        .advancedSearchtext {
            font-size: 12px;
            padding: 10px;
        }
    }

    .resultsContainer {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding: 15px 0;

        .noResultsText {
            font-size: 14px;
            text-align: center;
            color: $ColorRH;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 30px;
        }

        .noResultsTextRfTv {
            font-size: 14px;
            text-align: center;
            color: $ColorRF;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 30px;
        }

        .searchLoading {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            padding-top: 30px;
        }

        .error {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            padding-top: 30px;
            color: $error-text-color;
            text-transform: uppercase;

        }

        .results {
            /* display: flex;
            flex-direction: row;
            
            flex-wrap: wrap;
            margin: 20px 0;*/
            position: relative;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            padding: 0 3.4% 0 4%;

            @media (pointer:coarse) {
                .noResultsText {
                    font-size: 12px;
                }

            }

            .hit {
                width: 296px;
                //height: 166px;
                // background: #555555;
                margin-bottom: 5px;
                //margin-left: 5px;
                margin-right: 5px;

                :global(.rhf-swiper-slide) {
                    width: 296px;
                    height: 166px;
                }
            }
        }

        .results> :first-child {
            >div {
                transform-origin: left;
            }
        }

        .results> :last-child {
            margin-right: 0px;

            >div {
                transform-origin: right;
            }
        }

        .results:hover {
            z-index: 2;
        }
    }
}

.formControlContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}