.footer {
    text-align: center;
    background: #222222;
    padding: 10px 0 70px 0;
    font-size: 16px;
}

.loadingContentError {
    width: 100%;
    font-size: 1.4vw;
    margin: 1em;
    text-align: center;
    cursor: pointer;
}

.loadingContentError:hover {
    text-decoration: underline;
}


.libraryAlert {
    margin-top: 60px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #000000;
    padding: 15px 0px;
    background-color: #3ab3e6;
    cursor: pointer;
}