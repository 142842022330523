@import '../global/global.scss';

.root {
    display: flex;
    flex-direction: column;
    background-color: #666;
    margin-top: 10px;
}

.trainingPlanProgress {
    font-size: 18px;
    text-transform: uppercase;
    padding: 8px 0 0 0;
    text-align: center;
    font-weight: 700;
    color: #333;
}

.planTitleContainer {
    padding: 2px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.error {
    color: #f44336;
    margin-left: 1em;
    font-size: 0.9rem;
    margin-top: -0.5em;
    /* text-align: end; */
    margin-bottom: 0.5em;
}

.planTitle {
    //flex: 1;
    font-weight: bold;
    font-size: 14px;
    color: $defaultColorText;
    text-transform: uppercase;
}

.buttonRF {
    font-size: 12px;
    cursor: pointer;
    /* margin-right: 5px; */
    display: flex;
    justify-content: center;
    background-color: $ColorRF;
    color: white;
    border-radius: 4px;
    padding: 5px;
    width: 80px;
    text-transform: uppercase;
    font-weight: 700;
}

.buttonRH {
    font-size: 12px;
    cursor: pointer;
    /* margin-right: 5px; */
    display: flex;
    justify-content: center;
    background-color: $ColorRH;
    color: white;
    border-radius: 4px;
    padding: 5px;
    width: 80px;
    text-transform: uppercase;
    font-weight: 700;
}

@media (max-width: 500px) {
    .button {
        padding: 4px;
    }
}

.buttonRF:hover {
    background-color: #3d7da2;
    //color: black;
}

.buttonRH:hover {
    background-color: #638025;
    // color: black;
}

.planInfoInProgress {
    margin-top: 1em;
    display: flex;

    // overflow-x: scroll;
    justify-content: space-evenly;
    align-items: baseline;
}

.planInfoButtons {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: center;
}

.inProgress {
    margin: 1em;
    font-size: 2em;
}

.planZeroSeen {
    margin: 10px 0;
    font-size: 16px;
}

.planInfoWrapper {
    flex: 1;
    display: flex;
    background-color: #222;
    // overflow-x: scroll;
    justify-content: space-evenly;

    :global(.swiper-container) {
        overflow: hidden !important;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.planInfoItem {
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.planInfoItemNumber {
    @if $brandFlag =="rhf" {
        background-color: $ColorRH;
    }

    @else {
        background-color: $ColorRF;
    }

    border-radius: 50%;
    margin: 10px;
    width: 30px;
    line-height: 30px;
    font-weight: bold;
    font-size: 18px;
}

.planInfoItemNumberNotSeen {
    background-color: #666;
}

.planInfoItemDate {
    font-weight: bold;
    margin: 0;
    font-size: 12px;
}

.planInfoItemCalories {
    color: #afafaf;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
}