@import '../global/global.scss';

.root {
    font-family: $main-font-family;
    color: $main-front-color;
    display: flex;
    flex-direction: column;
    
    background:$homeBackgroud;
    padding: 15px 40px;
    text-align: center;

    .title {
        font-weight: bold;
        font-size: 20px;
    }
    
    .suggestions {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .suggestion {
            .image {
                width: 120px;
                cursor: pointer;
                position: relative;

                :global(.fa-play-circle) {
                     position: absolute;
                     left: 35%;
                     top: 25%;
                     font-size: 48px;
                     visibility: hidden;
                }

                img {
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            > div{
                font-size: 14px;
            }

            .image:hover {
                :global(.fa-play-circle) {
                     visibility:visible;
                }
            }
        }
    }
}
