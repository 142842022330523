@import '../global/global.scss';

.root {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background: black;
    font-family: $main-font-family;
    color: $main-front-color;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    align-items: center;
}

.statsCloseButton {
    cursor: pointer;
    color: white;
    z-index: 1000000;
    font-size: 24px;
    align-self: flex-end;
    margin: 10px;
}

.statsWrapper {
    display: flex;
    flex-direction: column;
    background-color: #222;
    border-radius: 10px;
    padding: 50px 0;

    .stats {
        margin-bottom: 40px;
        align-self: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        .titleGreatJob {
            padding: 15px 0;
            font-size: 44px;
            font-weight: 700;
            color: $ColorRH;
            background: $homeBackgroud;
        }

        .titleGreatJobRfTv {
            padding: 15px 0;
            font-size: 44px;
            font-weight: 700;
            color: $ColorRF;
            background: $homeBackgroud;
        }

        .textStats {
            font-size: 20px;
            color: #e5e5e5;
            padding: 20px 0;
            font-weight: 700;
        }

        .statsContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .statsContainerItem {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                text-transform: uppercase;

                .statsContainerItemIcon {
                    font-size: 34px;
                    color: #555;
                    padding: 10px 0px;
                }

                .statsContainerItemTitle {
                    color: #e5e5e5;
                    font-size: 16px;
                    font-weight: 700;
                }

                .statsContainerItemValue {
                    color: $ColorRH;
                    font-size: 20px;
                    padding: 10px 0px;
                }

                .statsContainerItemValueRfTv {
                    color: $ColorRF;
                    font-size: 20px;
                    padding: 10px 0px;
                }
            }
        }
    }
}