@import '../global/global.scss';

.notificationsContainer {
   // margin: 24px;
    height: 100vh;
    overflow-y: auto;

}

.noNotifications {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    height: 100%;
    font-size: 3em;
    text-align: center;
    flex-direction: column;
}

.notificationList {
   // max-height: 70vh;
    overflow-y: auto;
}

.bottomMargin {
    height: 5vh;
}

.linked {
    cursor: pointer;
}

.notificationRow {
    display: flex;
    flex-direction: row;
    margin: 1em;
    background: #444444;
    padding: 0.5em;
    border-radius: 5px;

    .text {
        flex: 1;
        padding: 10px;

        .date {
            font-size: 14px;
            font-weight: 700;
            color: #b1b1b1;
        }

        .title {
            font-weight: 700;    
            font-size: 16px;
            padding: 11px 0;
            line-height: 16px;
            text-transform: uppercase;
        }

        .body {
            font-size: 14px;
            line-height: 15px;
        }
    }

     .img {
         flex: 0.2;
         margin: 10px;
         margin-right: 20px;
         align-self: center;
         img {
            width: 100%;
            
         }
    }

    .actions {
        cursor: pointer;
        text-align: center;
        align-self: center;
        color: #e5e5e5;
        margin-right: 10px;
        font-size: 16px;
    }
}