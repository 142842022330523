@import '../global/global.scss';

$black: #333333;
$blue: #686de0;
$gray: #d3d3d3;
$green: #6ab04c;
$red: #dc3545;
$white: #ffffff;

code {
    display: inline-block;
    padding: 0.25rem 0.5rem;
  }
  
  .pinField-container {
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    justify-content: center;
    margin: 4rem 0;
  }
  
  .pinField {
    border: 1px solid $gray;
    border-right: none;
    font-size: 2rem;
    height: 4rem;
    outline: none;
    text-align: center;
    transition-duration: 250ms;
    transition-property: color, border, box-shadow, transform;
    width: 4rem;
  
    &:nth-of-type(1) {
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:nth-of-type(5) {
        border-radius: 0.5rem 0 0 0.5rem;
    }

    &:nth-of-type(5) {
       margin-left: 1em;
    }

    &:nth-of-type(4) {
        border-radius: 0 0.5rem 0.5rem 0;
        border-right: 1px solid $gray;
    }

    &:nth-of-type(8) {
      border-radius: 0 0.5rem 0.5rem 0;
      border-right: 1px solid $gray;
    }
  
    &:focus {
      border-color: $blue;
      box-shadow: 0 0 0.25rem rgba($blue, 0.5);
      outline: none;
  
      & + .pinField {
        border-left-color: $blue;
      }
    }
  
    &:invalid {
      animation: shake 5 linear 75ms;
      border-color: $red;
      box-shadow: 0 0 0.25rem rgba($red, 0.5);
  
      & + .pinField {
        border-left-color: $red;
      }
    }
  
    &.complete {
      border-color: $green;
      color: $green;
  
      &[disabled] {
        background: rgba($green, 0.1);
        cursor: not-allowed;
        opacity: 0.5;
      }
  
      & + .pinField {
        border-left-color: $green;
      }
    }
  }
  
  @keyframes shake {
    from {
      transform: translateY(-5%);
    }
    to {
      transform: translateY(5%);
    }
  }

.loginContainer {
    height: 100vh;
    position: relative;
    color: $defaultColorText;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 5em;
    // justify-content: center;

    .logo {
        max-width: 250px;
        align-self: flex-end;
        padding: 10px 20px 10px 0;
    }

    @media (pointer:coarse),
    (orientation:portrait) {
        .logo {
            align-self: center;
        }
    }

    h2 {
        color: white;
        margin-bottom: 1px;
        margin-top: 1px;
    }

    .error {
        font-size: 2em;
        margin-top: 0.5em;
        font-weight: bold;
        color: $error-text-color;
        text-align: center;
    }

    .fieldsContainer {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        .form {
            width: 100%;

            .field {
                width: 100%;
                margin: 20px 0;
                font-size: 14px;
                text-align: center;

            }

            .fieldSignIn {
                width: 100%;
                text-align: center;
                margin: 15px 0;

                .message {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }


        }
    }
}

.recoverSession {
    font-size: 24px;
}

.formContainer {
    /*margin-top: 30px;
    display: flex;
    flex-direction: row;
    background: white;
    border-radius: 20px;
    padding: 20px;*/

    margin-top: 5em;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    //padding: 20px;
    width: fit-content;
    z-index: 1;
}


.formContainer * {
    z-index: 10;
}

.logoContainer {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;
}

.logoImgSize {
    width: 60%;
    border-radius: 20px;
    max-width: 350px;
}

@media (max-width: 600px) {
    .loginContainer {
       // justify-content: flex-start;
        //height: auto;

        .logo {
            width: 70%;
        }
    }

    .formContainer {
        flex-direction: column;
        //padding: 20px 0 0 0;

    }

    .logoImgSize {
        // width: 60%;

    }

    .logoContainer {
        justify-content: center;
        padding: 20px 0 0 0;
    }
}

.textInstructions {
    font-size: 2.5em;
    color: $defaultColorText;
    text-align: center;
}