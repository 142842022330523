.button {
    // font-size: 1.5rem;
    // cursor:pointer;
    // //border: 1px solid black;
    // background:rgba(255,255,255,0.7);
    // color: black;
    // border-radius: 4px;
    // display: inline-block;
    // padding: 10px;
    // // padding-left:40px;
    // // padding-right:40px;
    // font-weight: bold;
    // flex-shrink: 0;

    font-size: 18px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.7);
    color: black;
    border-radius: 4px;
    display: inline-block;
    padding: 0.8rem 10px;
    height: 100%;
}

@media (max-width:562px){
    .button{
        font-size: 14px;
    }
}

.button-more {
        background: rgba(109,109,110,0.7);
        color: white;
        // padding-left:20px;
        // padding-right:20px;
        margin-left: 1rem;
        .fa-info-circle {
            //margin-left: 10px;
            margin-right: 0.5rem;
        }
    }

