@import '../global/global.scss';

.rootCenter {
    width: 100%;
    position: fixed;
    top: 0px;
    bottom: 0px;
}

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $main-font-family;
    color: $main-front-color;
    justify-content: center;

    .spinner {
        font-size: 96px;
        font-weight: bold;
    }

    .msgSmall {
        font-size: 24px;
    }

    .msgBig {
        margin-top: 10px;
        font-size: 28px;
    }

     .spinnerM {
        font-size: 48px;
        font-weight: bold;
    }

    .msgSmallM {
        font-size: 18px;
    }

    .msgBigM {
        margin-top: 8px;
        font-size: 21px;
    }

    .spinnerS {
        font-size: 36px;
        font-weight: bold;
    }

    .msgSmallS {
        font-size: 12px;
    }

    .msgBigS {
        margin-top: 5px;
        font-size: 14px;
    }
}