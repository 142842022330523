.prev, .next {
    cursor: pointer;
    color: white;
    font-size: 30px;
    margin:0 20px;
    filter:drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.6));
}

@media (min-width: 641px) {
    .outstander {
        .img {
            display: block;
        }

        .imgMobile {
            display: none;
        }
    }
}

@media (max-width: 640px) {
    .outstander {
          .img {
              display: none;
          }
  
          .imgMobile {
              display: block;
          }
          .title {
              font-size: 16px !important;
          }
          .description {
              font-size: 12px !important;
          }
      }
      .outstander:before {
          content:"";
          display: block;
          padding-top: 87.5% !important;
      }
  }

.outstander:before {
    content:"";
    display: block;
    padding-top: 37.5%;
}

.outstander {
    margin: 0px;
   // position: relative;
   display: inline-block;
   width: 100%;
   position: relative;

    .img {
        position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
    }

    .imgMobile {
        position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;

    }

    .controlsContainers {
        position: absolute;
        bottom: 7%;
        left: 4%;
        width: 95%;
        z-index: 1;
    }

    .arrowsContainer{
        position: absolute;
        bottom: 50%;
        width: 100%;
        z-index: 1;
        display: flex;
        justify-content: space-between;
    }

    .title {
        font-weight: bold;
        padding: 0.2rem 1rem; 
        font-size: 2em;
        color: white;
        border-radius: 0.5rem;
        background-color: rgba(34, 34, 34, 0.5);
        display: inline;
    }

    .description {
        top: 190px;
        /* padding: 5px; */
        font-size: 1.5rem;
        color: white;
        text-shadow: 2px 2px 4px black;
        /* margin-bottom: 40px; */
        /* flex: 1; */
        width: 65%;
    }

    .buttons{
        display: flex;
        align-items: center;
        min-width: 200px;
    }
}

// @media (orientation: landscape) {
//     .outstander {
//         margin: 0px;
//         position: relative;
        
//         .img {
//             display: block;
//         }

//         .imgMobile {
//             display: none;
//         }
//     }
// }

// @media (orientation: portrait) {
//   .outstander {
//         .img {
//             display: none;
//         }

//         .imgMobile {
//             display: block;
//         }
//     }
// }