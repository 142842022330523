$main-front-color: white;
$main-back-color: #222222;

$homeBackgroud: #0b0b0b;
$main-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$error-text-color: #aa2222;
$error-text-color: #ff3636;

$defaultColorText: #e5e5e5;

$brandFlag: "rf";

$ColorRH: #97bd45;
$ColorRF: #5fb1df;

$rftvTextColor: #111;
$modal-border-radius: 10px;

:global(.MuiDialog-root) {
    z-index: 200000 !important;
}

body {
    background: $homeBackgroud;
    color: #e5e5e5;
    overflow-x: hidden;
    //  padding-bottom: 50px;
}

html {
    font-size: 16px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}



.radicalModal {
    overflow-anchor: none;
    outline: none !important;
    // width: 50vw;
    width: 100%;
    z-index: 100000;
    position: relative;
    color: $main-front-color;
    font-family: $main-font-family;
    padding-top: 5vh;
    padding-bottom: 5vh;
    min-height: 90vh;
    background: rgba(0, 0, 0, 0.5);

    .contents {
        background: #222;
        z-index: 100000;
        width: 850px;
        margin: auto;
        min-width: 850px;
        border-radius: $modal-border-radius;

        .titleContainer {
            background: #222;
            padding: 1em;
            padding-top: 22px;
            border-radius: $modal-border-radius;
            position: relative;
            display: flex;

            h3 {
                font-size: 20px;
                margin: 0px;
                flex: 0 0 95%;
            }
        }
    }
}

@media (max-width:640px) {
    .radicalModal {
        padding: 0px;

        .contents {
            width: 100vw;
            border-radius: 0px;
            min-width: 0px;
            min-height: 100vh;
        }
    }

}

@media (max-width: 849px) and (min-width:641px) {
    .radicalModal {
        .contents {
            width: 95vw;
            min-width: 0px;
        }
    }
}