.root {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background: black;
}

.contents {
    margin: 10px;
    height: 100%;
}

.vimeoPlayer {
    height: 90%;
    width: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}