@import '../global/global.scss';

.content_loading {
    margin: 0.5em;
    font-size: 1.6vw;
}

.content_error {
    margin: 0.5em;
    font-size: 1.6vw;
}

.isNew {
    @if $brandFlag =="rhf" {
        background: $ColorRH;
    }

    @else {
        background: $ColorRF;
    }

    // background:  ? : #3fb3e4;
    padding: 2px 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 10px;
    border-radius: 2px;
    color: $defaultColorText;
}

.planInfoWrapper {
    margin-top: 1em;
    display: flex;
    background-color: #222;
    overflow-x: scroll;
    justify-content: space-evenly;
}

.planInfoItem {
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.planInfoItemNumber {
    @if $brandFlag =="rhf" {
        background-color: $ColorRH;
    }

    @else {
        background-color: $ColorRF;
    }

    border-radius: 50%;
    margin: 1rem;
    width: 2.5rem;
    line-height: 2.5rem;
    font-weight: bold;
}

.planInfoItemDate {
    font-weight: bold;
    margin: 0;
}

.planInfoItemCalories {
    color: #afafaf;
    text-align: center;
    font-weight: bold;
}

.progress {
    background: #cc2222;
    height: 5px;
    width: 0%;
    position: relative;
    bottom: 5px;
}

.seasonsContainer {
    .seasonsHeaderContainer {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;

        .listTitle {
            font-size: 24px;
            font-weight: 700;
        }

        .seasonSelector {
            font-weight: bold;
            font-size: 16px;

            .seasonSelect {
                border-radius: 5px;
                font-size: 16px;
                padding: 8px;
                border: 2px solid #444;
                background-color: #222;
                color: #e5e5e5;
                font-weight: 700;
            }
        }
    }

    .seassonContents {
        .chapter {
            cursor: pointer;
            //padding: 25px;
            padding: 1em;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #555555;

            .index {
                // font-size: 20px;
                // text-align: center;
                // margin-left:25px;
                // margin-right:25px;
                font-weight: bold;
                align-self: center;
                //-------
                //justify-content: center;
                flex: 0 0 5%;
            }

            .chapterTextContainer {
                //flex: 0 0 70%;
                padding: 0 1em;
                font-weight: 700;
                color: #b1b1b1;

                .title {
                    color: #fff;
                    text-transform: uppercase;
                    display: flex;
                    flex-direction: row;

                    div {
                        padding-right: 10px;
                    }

                    span {
                        //display: none;
                    }
                }

                .chapterDescription {
                    font-weight: lighter;
                    color: #d2d2d2;
                    font-size: 14px;
                    line-height: 20px;
                }

            }

            .imageContainer {
                // height: 80px;
                // width: 160px;
                position: relative;
                //----------
                //align-self: center;
                flex: 0 0 20%;
                width: 100%;

                :global(.fa-play-circle) {
                    position: absolute;
                    left: 50%;
                    top: 45px;
                    font-size: 50px;
                    visibility: hidden;
                    margin-left: -25px;
                    margin-top: -25px;
                }

                :global(.fa-check-circle) {
                    position: absolute;
                    left: 50%;
                    top: 45px;
                    font-size: 50px;
                    margin-left: -25px;
                    margin-top: -25px;
                    opacity: 0.6;
                }


                :global(.fa-lock) {
                    position: absolute;
                    left: 50%;
                    top: 45px;
                    font-size: 50px;
                    margin-left: -25px;
                    margin-top: -25px;
                }

                img {
                    // height: 80px;
                    // width: 160px;
                    width: 100%;
                    // background-size: cover;
                    // background-repeat: no-repeat;
                    // background-position: center;
                }
            }
        }

        @media (max-width:640px) {
            .chapter {
                flex-direction: column;

                .index {
                    padding-bottom: 10px;
                    display: none;
                }

                .imageContainer {
                    :global(.fa-play-circle) {

                        top: 50%;

                    }

                    :global(.fa-check-circle) {

                        top: 50%;

                    }


                    :global(.fa-lock) {
                        top: 50%
                    }
                }
            }
        }

        .chapter:hover {
            background: #444444;
            border-radius: 5px;

            .imageContainer {
                :global(.fa-play-circle) {
                    visibility: visible;
                }
            }
        }

        .chapterLocked {
            cursor: initial;

            .imageContainer {
                opacity: 0.6;
            }
        }

        .chapterLocked:hover {
            background: #444444;
            border-radius: 5px;
        }
    }

    @media (max-width: 849px) and (min-width:0px) {
        .seasonsContainer {
            .seassonContents {
                .chapter {
                    .imageContainer {
                        :global(.fa-play-circle) {
                            font-size: 1em;
                        }

                        :global(.fa-lock) {
                            font-size: 1em;
                        }

                        :global(.fa-check-circle) {
                            font-size: 1em;
                        }
                    }
                }

                .title {
                    font-size: 12px;
                }

                .chapterDescription {
                    font-size: 11px;
                    line-height: 14px;
                }
            }
        }
    }

}

@media (pointer:coarse) {

    .seasonsContainer {
        .seassonContents {
            .chapter {
                .chapterTextContainer {
                    .title {
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }

}

.shareButton {
    cursor: pointer;
}

.more {
    padding: 15px;
    font-size: 1.2em;
}

.header {
    width: 100%;
    position: relative;

    .itemBackground {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to top, #333333, transparent 35%);
    }

    h3 {
        margin: 0;
        margin-left: 1.2em;
        margin-bottom: 1em;
        font-size: 1.5em;
    }

    .headerImage {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        //max-height: 400px;
    }

    .actions {
        display: flex;
        flex-direction: row;
        margin-left: 1em;
        margin-top: 1em;
        padding-bottom: 1em;
        align-items: center;
        position: absolute;
        top: 80%;

        .playButton {
            margin-right: 32px;
        }

        .favButton {
            margin-right: 32px;
        }

        .shareButton {
            margin-right: 32px;
        }

        .lockIcon {
            font-size: 24px;
        }
    }
}

.detailContainer {
    padding-bottom: 15px;

    .nextVideoTextContainer {
        display: flex;
        align-items: center;
        padding: 0 15px;
    }

    .nextVideoIcon {
        font-size: 18px;
        text-transform: uppercase;
        color: #222;
        font-weight: 500;
        background-color: #e5e5e5;
        border-radius: 4px;
        padding: 4px;
    }

    .nextVideoName {
        font-size: 18px;
        text-transform: uppercase;
        color: #bbb;
        font-weight: 500;
        margin-left: 10px;

    }

    .nextVideoWeek {
        font-size: 18px;
        text-transform: uppercase;
        color: #e5e5e5;
        font-weight: 500;
        padding-left: 10px;
    }

    .slideDescription {
        font-size: 16px;
        text-align: justify;
        padding: 15px
    }

    .metadataContainer {
        display: flex;
        overflow-x: scroll;
        justify-content: space-around;
        background-color: #222;
        padding: 15px;

        .metadata {
            font-size: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 170px;

            .metadataIcon {
                font-size: 24px;
                color: #555555;
            }

            .metadataTitle {
                font-weight: bold;
                padding: 0.5em;
                display: inline-block;
                text-transform: uppercase;
                font-size: 14px;
            }

            .metadataValues {
                padding-right: 1em;
                display: inline-block;
                font-size: 14px;
                color: #d2d2d2;
            }
        }
    }
}