.root {
    //top: 0;
    //bottom: 0;
    //left: 0;
    //right: 0;
    position: absolute;
    background: black;
    width: 100%;
    outline: none !important;
    overflow-anchor: none;

    .videoInfoContainer {
        width: 100%;
        /* text-align: center; */
        background-color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;

        .videoInfoTitle {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 1em;
            margin-top: 1em;
        }

        .metadataContainer {
            background: #222;
            width: 100%;
        }

        .videoInfoDescription {
            font-size: 14px;
            padding: 30px;
            line-height: 22px;
            background-color: #333;
        }
    }
}

.contents {
    //margin: 10px;
    //max-height: 88vh;
    height: calc(56.25vw);
}

.vimeoPlayer {
    height: 100%;
    width: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.btnPlayRight {
    float: right;
}