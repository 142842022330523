
.imgContainer {
    position: relative;
    width: 100%;
    background-color: #333;
    // opacity: 0.8;
    // background-image: radial-gradient( ellipse farthest-corner at 10px 10px , #cacaca, #cacaca 50%, #e5e5f7 50%);
    // background-size: 10px 10px;
    // border-radius: 4px;
    
    .imgAspect {
        position: relative;
        padding-bottom: calc(100% * 9 / 16);

        .img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            :global(.lazy-load-image-background) {
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
    }
}

.blurhash {
    canvas {
    }
}