@import '../global/global.scss';

.root {
    background: $main-back-color;
    font-family: $main-font-family;
    color:$main-front-color;
    //padding: 15px;
    position: absolute;
    top: 0; 
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
    background-image: url("res/img/rhf_back.jpg");

    .header {
        padding: 15px 0;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);

        .title {
            font-size: 24px;
            margin-top: 1em;
            font-weight: bold;
            color: white;
            text-align: center;
        }

        .logosContainer{

            display: flex;
            justify-content: center;
            align-items: center;
            

            .logo {
                max-width: 250px;
                padding: 0 50px;
            }
            @media (max-width:599px){
                
    
                .logo {
                    max-width: 150px;
                    padding: 0 20px;
                }
    
            }
            
        }

       

        
    }
}

.imageTransition{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient( 0deg,#0b0b0b,transparent 20%);
}

.MuiDialogRoot {
     z-index: 3000000 !important;
}