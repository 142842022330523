@import '../global/global.scss';

@keyframes Pulsate {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.rhfSwiperSlide {
    position: relative;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    background: $homeBackgroud;
    width: 100%;
}

.liveTitle {
    //animation: Pulsate 2s linear infinite;
    background: #b70000;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    top: 75%;
    width: fit-content;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 10;
    text-transform: uppercase;

    svg {
        font-size: 10px;
    }
}

.isNewTitle {
    background: $ColorRH;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 12px;
    text-transform: uppercase;
    top: 75%;
    width: fit-content;
    padding: 2px 5px;
    font-weight: 700;
    color: white;
    text-align: center;
    position: absolute;
    z-index: 10;
}

.isNewTitleRfTv {
    background: $ColorRF;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 12px;
    text-transform: uppercase;
    top: 75%;
    width: fit-content;
    padding: 2px 5px;
    font-weight: 700;
    color: white;
    text-align: center;
    position: absolute;
    z-index: 10;
}


/*@media (min-width:640px){
    .isNewTitle{
        top: 75%;
    }
    .liveTitle {
        top: 75%;
    }

}*/


.rhfSwiperSlideLarge {
    //height: 498px;
    background: $homeBackgroud;

    .test {
        position: relative;
        display: block;
    }

    .test>span::before {
        display: block;
        content: "";
        padding: 75% 0;

    }

    .test>span>img {
        position: absolute;
        top: 0;
        bottom: 0;
    }

    .isNewTitle {
        @if $brandFlag =="rhf" {
            background: $ColorRH;
        }

        @else {
            background: $ColorRF;
        }

        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        font-size: 12px;
        text-transform: uppercase;
        top: 75%;
        width: fit-content;
        padding: 2px 5px;
        font-weight: 700;
        color: white;
        text-align: center;
        position: absolute;
        z-index: 10;
    }

    @media (max-width:390px) {
        .isNewTitle {
            top: 70%;
        }
    }

    @media (min-width:500px) and (max-width:640px) {
        .isNewTitle {
            top: 70%;
        }
    }
}

.index {
    position: absolute;
    right: 10px;
    bottom: 10%;
    font-size: 250px;
    -webkit-text-stroke: 5px white;
    -webkit-text-fill-color: rgba(1, 1, 1, 0.4);
    z-index: 100;
}

.infoContainer {
    position: absolute;
    width: 100%;
    background-color: #0b0b0b;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.infoBar {
    display: none;
    flex-direction: column;
    opacity: 0;
    //position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: $homeBackgroud;

    .nameBar {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 5px;
        //margin-bottom: 0.3em;
        //margin-top: 0.4em;
        margin-bottom: 2px;


        .description {
            font-size: 0.5em;
        }

        .captions {
            margin-top: 0.5em;
            margin-left: 0.7em;
            margin-bottom: 0px;
            font-size: 1.1em;
            flex: 1;
        }

        .nameCaption {
            flex: 1;
            font-size: 10px;
            margin-left: 0.2em;

        }

        .actions {
            margin-top: 0.1em;


            .playButton {
                font-size: 20px;
                margin-left: 0.3em;
                margin-right: 0.3em;
            }

            .favButton {
                margin-right: 16px;
                font-size: 16px;
                margin-left: 0.3em;
                margin-right: 0.3em;
            }

            .shareButton {
                font-size: 20px;
                margin-left: 0.3em;
                margin-right: 0.3em;
            }
        }
    }
}

.infoBarExtraContainer {
    display: none;
    flex-direction: row;
    flex-direction: column;
    padding-bottom: 5px;
    justify-content: space-between;
    opacity: 0;

    .infoBarExtraItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0.45;

        .infoBarExtraItemTitle {
            font-size: 9px;
            color: #555;
            font-weight: 700;
        }

        .infoBarExtraItemText {
            font-size: 9px;
            color: #e5e5e5;
            font-weight: normal;
        }
    }

    .infoBarExtraSeparator {
        font-size: 3px;
        color: #888;
        align-self: center;
    }
}

@media(pointer:coarse) {
    .infoContainer {
        display: none;
    }
}

.infoBar:hover {
    background: #0b0b0b;
}

.rhfSwiperSlide {
    transform-origin: center;
}

.rhfSwiperSlide:hover {
    z-index: 100000;
    transform: scaleY(1.55) scaleX(1.55);
    box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 10px;
    transition: 0.2s ease-in-out;
    transition-delay: 0.5s;
    // border-bottom-left-radius: 0.5rem;
    // border-bottom-right-radius: 0.5rem;
    border-radius: 4px;

    .infoContainer {
        box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 10px;
    }


    .infoBar {
        opacity: 1;
        transition: 0.3s;
        transition-delay: 0.2s;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        display: flex;
    }

    .infoBarExtraContainer {
        opacity: 1;
        transition: 0.3s;
        transition-delay: 0.2s;
        // border-bottom-left-radius: 4px;
        // border-bottom-right-radius: 4px;
        display: flex;
    }
}

.rhfSwiperSlideLarge:hover {
    z-index: 100000;
    transform: scaleY(1.4) scaleX(1.4);

    transition: 0.2s ease-in-out;
    transition-delay: 0.5s;

    .infoBar {
        opacity: 1;
        transition: 0.3s;
        transition-delay: 0.5s;
        transform: scaleY(1) scaleX(1);
    }

    .infoBarExtraContainer {
        opacity: 1;
        transition: 0.3s;
        transition-delay: 0.5s;
        transform: scaleY(1) scaleX(1);
    }
}

.imgContainer {
    position: relative;
    width: 100%;
    background-color: $homeBackgroud;
    border-radius: 4px;
    // opacity: 0.8;
    // background-image: radial-gradient( ellipse farthest-corner at 10px 10px , #cacaca, #cacaca 50%, #e5e5f7 50%);
    // background-size: 10px 10px;
    // border-radius: 4px;

    .imgAspect {
        position: relative;
        padding-bottom: calc(100% * 9 / 16);

        .img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            canvas {
                border-radius: 4px;
            }

            :global(.lazy-load-image-background) {
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
    }
}

.placeholder {
    background: #555;
    width: 100%;
    height: 100%;
}

.rhfSwiperSlideLarge {
    .imgContainer {
        .imgAspect {
            position: relative;
            padding-bottom: calc(100% * 96 / 64);
        }
    }
}

.blurhash {
    position: initial !important;

    canvas {
        border-radius: 4px;
    }
}

@media (pointer:coarse) {
    .rhfSwiperSlide:hover {
        transform: none;
        box-shadow: none;
        transition: none;

        .infoBar {
            opacity: 0;
            transition: none;
        }

        .infoBarExtraContainer {
            opacity: 0;
            transition: none;
        }

    }

    .rhfSwiperSlideLarge:hover {
        transform: none;
        box-shadow: none;
        transition: none;

        .infoBar {
            opacity: 0;
            transition: none;
        }

        .infoBarExtraContainer {
            opacity: 0;
            transition: none;
        }

    }

}

// SEE: https://stackoverflow.com/questions/2717480/css-selector-for-first-element-with-class
:global(.swiper-wrapper) {
    :global(.swiper-slide-visible) {
        .rhfSwiperSlideVisible {
            transform-origin: left;
        }
    }

    :global(.swiper-slide-visible)~ :global(.swiper-slide-visible) {
        .rhfSwiperSlideVisible {
            transform-origin: center;
        }
    }


    @media (max-width: 499px) {
        :global(.swiper-slide-visible)~ :global(.swiper-slide-visible) {
            .rhfSwiperSlideVisible {
                transform-origin: right;
            }
        }
    }

    @media (min-width: 500px) and (max-width: 799px) {
        :global(.swiper-slide-visible)~ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible) {
            .rhfSwiperSlideVisible {
                transform-origin: right;
            }
        }
    }

    @media (min-width: 800px) and (max-width: 1099px) {
        :global(.swiper-slide-visible)~ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible) {
            .rhfSwiperSlideVisible {
                transform-origin: right;
            }
        }
    }

    @media (min-width: 1100px) and (max-width: 1399px) {
        :global(.swiper-slide-visible)~ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible) {
            .rhfSwiperSlideVisible {
                transform-origin: right;
            }
        }
    }

    @media (min-width: 1400px) {
        :global(.swiper-slide-visible)~ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible)+ :global(.swiper-slide-visible) {
            .rhfSwiperSlideVisible {
                transform-origin: right;
            }
        }
    }
}

.rhfSwiperSlideLastVisible {
    background: pink;
}

.infoBarAlwaysVisible {
    opacity: 1;
}