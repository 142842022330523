@import '../../global/global.scss';

.root {
  cursor: pointer;
  // font-size: 36px;
  color: $main-front-color;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000000;
  //-----
  //flex: 0 0 5%;
  text-align: center;
  font-size: 24px;
}