@import '../global/global.scss';

.root {
    font-family: $main-font-family;
    color: $main-front-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;

    .title {
        font-weight: bold;
        font-size: 24px;
    }
    
    .nextVideo {
        margin-top: 40px;
        width: 240px;
        height: 240px;
        align-self: center;

 
        .image {
            cursor: pointer;
            position: relative;

            :global(.fa-play-circle) {
                    position: absolute;
                    left: 35%;
                    top: 25%;
                    font-size: 64px;
                    visibility: hidden;
            }

            img {
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .image:hover {
            :global(.fa-play-circle) {
                    visibility:visible;
            }
        }
    
    }
}
