@import '../global/global.scss';

.rhfSwiperTitleContainer {
    flex-direction: row;
    align-items: center;
    display: inline-flex;
    padding-left: 4%;

    .rhfSwiperTitle {
        //text-align: start;
        /* font-size: 24px; */
        font-size: 24px;
        margin-bottom: 5px;
        font-weight: 700;
        cursor: default;

    }

    @media (max-width:1400px) and (min-width:641px) {
        .rhfSwiperTitle {
            font-size: 20px;
        }
    }

    .rhfSwiperTitleShowExpansion {
        cursor: pointer;
    }


    .rhfSwiperTitleMore {
        cursor: pointer;
        //padding: 0.6em;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s; //, width 0.3s ease-in-out;
    }

    @media (pointer:coarse) {
        .rhfSwiperTitleMore {
            cursor: pointer;
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s, visibility 0.3s; //, width 0.3s ease-in-out;
            font-size: 12px;
            padding-left: 10px;
            margin-bottom: 3px;
        }

    }
}

.rhfSwiperTitleContainer:hover {
    .rhfSwiperTitleMore {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s, visibility 0.3s; //, width 0.3s ease-in-out;
    }
}

.isNew {
    background: $ColorRH;
    padding: 2px 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 5px;
    border-radius: 2px;
    margin-bottom: 2px;
}

.isNewRfTv {
    background: $ColorRF;
    padding: 2px 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 5px;
    border-radius: 2px;
    margin-bottom: 2px;
}

@media (max-width:640px) {
    .isNew {
        font-size: 10px;
        padding: 1px 4px;
    }

    .rhfSwiperTitleContainer {
        .rhfSwiperTitle {
            font-size: 18px;
            color: #999;
        }
    }
}